import React from 'react';
import '../App.css';
import Main from './Main';

const App = () => {
  return (
    <Main />
  );
}

export default App;
